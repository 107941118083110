import React from "react";
import Fade from 'react-reveal/Fade';
import imagenperfil from "../img/perfilpagina.png";
import slidebd from "../img/slide/bd.jpg";
import slidehtml from "../img/slide/htmljavacss.jpg"
import slidegit from "../img/slide/git.jpeg"
import "../styles/home.css";
import Perfil from "../component/Perfil";
import Contacto from "../component/Contacto";
import skillSql from "../img/skills/skill-sql.png"
import skillprogramacion from "../img/skills/skill-programacion.png"
import skillfrontend from "../img/skills/skill-fronend.png"
import skillnube from "../img/skills/skill-nube.png"
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {imagenBd : skillSql }
    //this.imagens = skillfrontend;

    // Este enlace es necesario para hacer que `this` funcione en el callback
    this.cambiarImagen = this.cambiarImagen.bind(this);
  }
  cambiarImagen() { 
    this.setState(state => ({
      imagenBd: skillprogramacion
    })) 
  }

  render() {
    return (
      <React.Fragment> 
        <div className="" > 
          <div className="card-body" >
            <div className="row"   >
               <div className="col-xl-3 col-sm-6 col-12" style={{background:"white", padding:"0px"}}>
                <Fade left>
                   <img src={imagenperfil} alt="Logo manager" className="" width="90%" style={{borderRadius: "0PX"}}/>
                </Fade>
               </div>
              <div className="col-xl-3 col-sm-6 col-12 text-center" style={{background:"white"}}>
                <h4 className="card-title">Eleazar Curiel Monjaraz</h4>
                <h5><strong>FullStack Developer</strong></h5>
                <h5 className="card-title">Ing. en sistemas computacionales</h5>
                <Contacto />
              </div>
              <div className="col-xl-6 col-sm-12 col-12" style={{color:"black", background:"white"}}>
                  <p className="card-text text-justify" style={{fontSize:"17px"}}>
                    Ingeniero en sistemas con amplia experiencia en las fases del desarrollo de software a la
                    medida. He colaborado en el análisis e implementación de nuevas herramientas tecnológicas
                    que mejoren la productividad de empresas con la visión de crecimiento.
                </p>
              </div>
            </div>
          </div>

          <div className="card-footer text-muted text-center"  > 

              <div className="" style={{margin:"2%"}}>
                <div className="row">
                  <div className="col-xl-2 col-sm-3 col-6">
                    <div className="habilidades-transform" style={{backgroundColor:"#04406D"}}>
                      <p><strong>Bases de datos</strong></p>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                    <div className="habilidades-transform"  style={{backgroundColor:"#04406D"}}>
                      <p><strong>Programación</strong></p>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                      <div className="habilidades-transform"  style={{backgroundColor:"#04406D"}}>
                        <p><strong>Back-End</strong></p>
                      </div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                      <div className="habilidades-transform"  style={{backgroundColor:"#04406D"}}>
                        <p><strong>Frond-End</strong></p>
                      </div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                      <div className="habilidades-transform"  style={{backgroundColor:"#04406D"}}>
                        <p><strong>Versionar de código</strong></p>
                      </div>
                  </div>
                  <div className="col-xl-2 col-sm-3 col-6">
                      <div className="habilidades-transform"  style={{backgroundColor:"#04406D"}}>
                        <p><strong>Cloud compute service</strong></p>
                      </div>
                  </div>
                </div>
              </div>
          
          </div>
        </div>


        <div  width="100%" className="card">
        <Fade left>
          <div className="row">
            <div className="col-xl-1 col-sm-1 col-1" style={{background:"rgba(184, 181, 181, 0.466)"}} width="100%">
            </div>
            <div className="col-xl-5 col-sm-5 col-11  text-center"  style={{padding:"15px"}}>
              <img src={this.state.imagenBd} alt="Logo manager" className="" width="80%" onMouseOver={this.cambiarImagen}/>
            </div>
            <div className="col-xl-1 col-sm-1 col-1" style={{background:"rgba(184, 181, 181, 0.466)" }} width="100%" >
            </div>
            <div className="col-xl-5 col-sm-5 col-11 text-center"  style={{padding:"15px"}}>
              <img src={skillprogramacion} alt="Logo manager" className="" width="80%"/>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="row">
            <div className="col-xl-1 col-sm-1 col-1" style={{background:"rgba(184, 181, 181, 0.466)" }} width="100%">
            </div>
            <div className="col-xl-5 col-sm-5 col-11  text-center"  style={{padding:"15px"}}>
              <img src={skillfrontend} alt="Logo manager" className="" width="80%" />
            </div> 
            <div className="col-xl-1 col-sm-1 col-1" style={{background:"rgba(184, 181, 181, 0.466)" }}>
            </div>
            <div className="col-xl-5 col-sm-5 col-11 text-center"  style={{padding:"15px"}}>
              <img src={skillnube} alt="Logo manager" className="" width="80%"/>
            </div>
          </div>
        </Fade>
        </div>

        <div className="card container">
          <div className="row">
            <div className="col-xl-5 col-sm-12 col-12">
              <Perfil />
            </div>
            <div className="col-xl-7 col-sm-12 col-12">
             <div id="carouselExampleControls" className="carousel slide container" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={slidebd} alt="First slide" width="600px" height="400px"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={slidehtml} alt="Second slide" width="600px" height="400px"/>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={slidegit} alt="Third slide" width="600px" height="400px"/>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
